exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/aboutUs.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-book-a-call-tsx": () => import("./../../../src/pages/bookACall.tsx" /* webpackChunkName: "component---src-pages-book-a-call-tsx" */),
  "component---src-pages-candidates-tsx": () => import("./../../../src/pages/candidates.tsx" /* webpackChunkName: "component---src-pages-candidates-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-diversity-tsx": () => import("./../../../src/pages/diversity.tsx" /* webpackChunkName: "component---src-pages-diversity-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industry-roles-analytics-and-data-tsx": () => import("./../../../src/pages/industry-roles/analytics-and-data.tsx" /* webpackChunkName: "component---src-pages-industry-roles-analytics-and-data-tsx" */),
  "component---src-pages-industry-roles-enterprise-sales-tsx": () => import("./../../../src/pages/industry-roles/enterprise-sales.tsx" /* webpackChunkName: "component---src-pages-industry-roles-enterprise-sales-tsx" */),
  "component---src-pages-industry-roles-marketing-tsx": () => import("./../../../src/pages/industry-roles/marketing.tsx" /* webpackChunkName: "component---src-pages-industry-roles-marketing-tsx" */),
  "component---src-pages-industry-roles-presales-and-solution-engineering-tsx": () => import("./../../../src/pages/industry-roles/presales-and-solution-engineering.tsx" /* webpackChunkName: "component---src-pages-industry-roles-presales-and-solution-engineering-tsx" */),
  "component---src-pages-industry-roles-product-tsx": () => import("./../../../src/pages/industry-roles/product.tsx" /* webpackChunkName: "component---src-pages-industry-roles-product-tsx" */),
  "component---src-pages-industry-roles-professional-services-tsx": () => import("./../../../src/pages/industry-roles/professional-services.tsx" /* webpackChunkName: "component---src-pages-industry-roles-professional-services-tsx" */),
  "component---src-pages-industry-roles-software-engineering-tsx": () => import("./../../../src/pages/industry-roles/software-engineering.tsx" /* webpackChunkName: "component---src-pages-industry-roles-software-engineering-tsx" */),
  "component---src-pages-industry-roles-technical-support-tsx": () => import("./../../../src/pages/industry-roles/technical-support.tsx" /* webpackChunkName: "component---src-pages-industry-roles-technical-support-tsx" */),
  "component---src-pages-insights-tsx": () => import("./../../../src/pages/insights.tsx" /* webpackChunkName: "component---src-pages-insights-tsx" */),
  "component---src-pages-ourwork-tsx": () => import("./../../../src/pages/ourwork.tsx" /* webpackChunkName: "component---src-pages-ourwork-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-services-contingent-tsx": () => import("./../../../src/pages/services/contingent.tsx" /* webpackChunkName: "component---src-pages-services-contingent-tsx" */),
  "component---src-pages-services-embedded-tsx": () => import("./../../../src/pages/services/embedded.tsx" /* webpackChunkName: "component---src-pages-services-embedded-tsx" */),
  "component---src-pages-services-search-tsx": () => import("./../../../src/pages/services/search.tsx" /* webpackChunkName: "component---src-pages-services-search-tsx" */),
  "component---src-pages-services-sourcing-tsx": () => import("./../../../src/pages/services/sourcing.tsx" /* webpackChunkName: "component---src-pages-services-sourcing-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-templates-post-details-post-details-tsx": () => import("./../../../src/templates/postDetails/postDetails.tsx" /* webpackChunkName: "component---src-templates-post-details-post-details-tsx" */),
  "component---src-templates-work-details-tsx": () => import("./../../../src/templates/workDetails.tsx" /* webpackChunkName: "component---src-templates-work-details-tsx" */)
}

